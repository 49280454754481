<template>
<v-col cols="12" sm="11" md="10" lg="7" style="padding-bottom:120px;"
      id="wallet-container" class="mx-auto px-0 mt-1">
  <Wallet :headerLarge="true"/>
</v-col>
</template>

<script>

//import axios from 'axios'
//import router from '../router/router'


import Wallet from '@/components/wallet/Wallet.vue'

export default {
  name: 'auth',
  components: { Wallet },
  data: () => ({
  }),

  async mounted(){
      this.$store.dispatch('app/incStat', '/wallet')
  },

  methods: {
  },
  
  computed: {
    
  },
  watch: {
      '$route.params.walletUid': { immediate: true, 
          async handler (){  //oldVal, val) {
            //console.log("route changed !", this.$route.params.walletUid)

            if(this.$route.params.walletUid != null)
              this.$store.dispatch('auth/setFocusWalletUid', { uid : this.$route.params.walletUid })
            else
              this.$router.push('/wallet/' + this.$store.state.auth.user.walletMain.uid)
          }
      },
  }, 
}
</script>
